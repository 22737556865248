<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="470px"
      persistent
    >
      <div class="text-right" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: right;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">&nbsp;&nbsp;{{ dialogTitle }}</div>
          <v-spacer></v-spacer>
          <v-icon
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleAddFilterModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text class="pb-0">
          <v-form lazy-validation ref="teamForm" class="pt-10">
            <v-row>
              <h3 class="ml-2">Category</h3>
              <v-col cols="12" class="px-2">
                <v-checkbox
                  color="#38A737"
                  label="Game"
                  value="1"
                  id="g1"
                  v-model="selected"
                ></v-checkbox>
                <v-checkbox
                  color="#38A737"
                  label="Wizfit"
                  v-model="selected"
                  value="2"
                ></v-checkbox>
                <v-checkbox
                  color="#38A737"
                  label="Assembly"
                  v-model="selected"
                  value="3"
                ></v-checkbox>
                <v-checkbox
                  color="#38A737"
                  label="Hotel"
                  v-model="selected"
                  value="4"
                ></v-checkbox>
                <v-checkbox
                  color="#38A737"
                  label="Task"
                  v-model="selected"
                  value="5"
                ></v-checkbox>
                <v-checkbox
                  color="#38A737"
                  label="PTO"
                  v-model="selected"
                  value="7"
                ></v-checkbox>
                <v-checkbox
                  color="#38A737"
                  label="Custom Event"
                  value="8"
                  v-model="selected"
                ></v-checkbox>
              </v-col>
            </v-row>
            <br />
            <br />
            <v-row>
              <v-col cols="6" class="px-2">
                <v-autocomplete
                  label="Team"
                  outlined
                  dense
                  v-model="team_id"
                  :items="teamNameList"
                  class="formFields"
                  item-text="team_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  @change="getteamPlayer()"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="6" class="px-2" v-if="this.team_id == null">
                <v-autocomplete
                  label="Player"
                  v-model="player_id"
                  :items="playerList"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="px-2">
                <v-autocomplete
                  label="Event support"
                  outlined
                  dense
                  v-model="event_support"
                  :items="eventSupportList"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="px-2">
                <v-autocomplete
                  outlined
                  dense
                  :items="salesRepList"
                  v-model="salesRep"
                  label="Sales REP"
                  color="#7253CF"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="px-2">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formatedStartDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Start Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    no-title
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6" class="px-2">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formatedEndDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="End Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7 mr-5"
              height="45px"
              style="margin-left: 41%; margin-bottom: 22px"
              @click="
                save(),
                  toggleAddFilterModal({ show: false }),
                  newMonthCalender()
              "
            >
              <span>Submit</span>
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";
import {
  API_ADMIN_TEAM_MANAGEMENT_GET_ROLE,
  API_ADMIN_TEAM_MANAGEMENT_GET,
  API_CALENDAR_EVENT_CATEGORY_LIST,
  API_ADMIN_TEAM_NAME_LIST,
  API_EVENT_TEAM_PLAYER_LIST_GET,
  API_USER_GET_EVENT_SUPPORT_LIST,
  API_USER_GET_SALES_REP_LIST,
} from "@/constants/APIUrls";
//import { ROUTER_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";
import moment from "moment";
export default {
  name: "AddEditTeamModal",
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      name: "",
      email: "",
      role: null,
      roleList: [],
      designation: "",
      categoryList: [],
      selected: [],
      selecteddattaa: localStorage.getItem("filterdata"),
      start_date: "",
      end_date: "",
      menu: false,
      menu1: false,
      teamNameList: [],
      team: "",
      player_id: "",
      playerList: [],
      team_name: "",
      team_id: "",
      sales_rep: "",
      salesRep: "",
      event_support: "",
      eventSupportList: [],
      salesRepList: [],
      teamlistsss2: [],
      teamlistsss1: [],
      eventss: [],
      events1: [],
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getShowFilter",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditFilterModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.addFilterModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Team Member Added";
      } else {
        return "Team Member Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Filter";
        case "edit":
          return "Edit Event";
        default:
          return "";
      }
    },
    formatedStartDate: {
      get() {
        if (this.start_date) {
          return moment(this.start_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedEndDate: {
      get() {
        if (this.end_date) {
          return moment(this.end_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleAddFilterModal: "calendarManagement/toggleFilterModal",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      this.getRole();
      this.getCategoryList();
      this.getTeamList();
      this.getteamPlayer();
      this.getEventSupportList();
      this.getSalesRepList();
    },
    closeCallBackModal() {
      this.loading = false;
    },
    getRole() {
      const self = this;
      self.loading = true;
      const successHandler = (res) => {
        const data = res.data;
        self.roleList = data.role_list;
        console.log(data);
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.loading = false;
      };
      const finallyHandler = function () {
        console.log("in finally");
        if (self.type === "edit") {
          self.getTeamDetail();
        }
      };

      let formData = {};
      Axios.request_GET(
        API_ADMIN_TEAM_MANAGEMENT_GET_ROLE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getTeamDetail() {
      const self = this;
      self.loading = true;
      const successHandler = (res) => {
        const data = res.data;
        console.log(data);
        this.name = data.team_detail.name;
        this.email = data.team_detail.email;
        this.role = data.team_detail.role;
        this.designation = data.team_detail.designation;
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.loading = false;
      };
      let formData = {};
      formData["user_id"] =
        self.$store.state.teamManagement.addEditViewModal.teamID;
      Axios.request_GET(
        API_ADMIN_TEAM_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getteamPlayer() {
      const successHandler = (res) => {
        this.playerList = res.data.player_list;
        //this.setplayers(res.data.player_list);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {};
      let formData = {};
      if (this.team_name) {
        formData["team_name"] = this.team_name;
      }
      Axios.request_GET(
        API_EVENT_TEAM_PLAYER_LIST_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getCategoryList() {
      const self = this;
      self.loading = true;

      const successHandler = (result) => {
        const data = result.data;
        console.log("dasdasdas", result.data);
        self.categoryList = data.category_list;
        console.log("categoryList", self.categoryList);
        self.loading = false;
      };
      const failureHandler = (result) => {
        console.log(result.data);
        self.loading = false;
      };
      const finallyHandler = function () {
        console.log("in finally");
      };
      let formData = {};
      Axios.request_GET(
        API_CALENDAR_EVENT_CATEGORY_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getTeamNameList() {
      console.log("!!!!!!!!!!!");
      const self = this;
      self.loading = true;
      console.log("!!!!!!!!!!!$$$");
      const successHandler = (res) => {
        console.log("!!!!!!!!!!!$$$*");

        const teamnaeee = res.data.team_name_list;
        const teamlistsss = [{ id: "", team_name: "Unassigned" }];
        self.teamNameList = teamlistsss.concat(teamnaeee);
        console.log("self.teamNameList", self.teamNameList);
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formData = {};
      return Axios.request_GET(
        API_ADMIN_TEAM_NAME_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getTeamList() {
      const successHandler = (res) => {
        const teamnaeee = res.data.team_name_list;
        const teamlistsss = [{ id: null, team_name: "Custom" }];
        const teamlistsss1 = [{ id: 0, team_name: "Unassigned" }];
        this.teamlistsss2 = teamlistsss.concat(teamlistsss1);
        this.teamNameList = this.teamlistsss2.concat(teamnaeee);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getSalesRepList();
      };
      let formData = {};
      Axios.request_GET(
        API_ADMIN_TEAM_NAME_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getEventSupportList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        const events = data.event_support_staff_list;
        const events1 = [{ id: 0, name: "Unassigned" }];
        self.eventSupportList = events1.concat(events);
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_USER_GET_EVENT_SUPPORT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getSalesRepList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.sales_rep_list);
        const salesrep1 = data.sales_rep_list;
        const salesrep2 = [{ id: 0, name: "Unassigned" }];
        self.salesRepList = salesrep2.concat(salesrep1);
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };
      const finallyHandler = function () {};

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_SALES_REP_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    save() {
      localStorage.setItem("eventid", this.selected);
      localStorage.setItem("teamId", this.team_id);
      localStorage.setItem("playerId", this.player_id);
      localStorage.setItem("eventsupport", this.event_support);
      localStorage.setItem("salesrep", this.salesRep);
      localStorage.setItem("startdatefilter", this.start_date);
      localStorage.setItem("enddatefilter", this.end_date);
    },
    newMonthCalender() {
      location.reload();
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-input--selection-controls {
  margin-top: 4px;
  padding-top: 4px;
}
</style>
